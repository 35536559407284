import * as Sentry from '@sentry/react';

export function init({ dsn, environment }) {
  if (!dsn) { return; }

  Sentry.init({
    dsn,
    integrations: [],
    environment,
    tracesSampleRate: 1.0,
  });
}
